
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import Header from './components/Header';
import './pages/style.css';
import './pages/responsive.css';
import Footer from './components/Footer';
import Home from './pages/Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from './components/Scrolltop';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Astrodetails from './pages/Astrodetails';

function App() {
  return (
    <>
  <BrowserRouter>
  <Header/>
  <Routes>
    <Route path="/" Component={Home}/>
    <Route path="/astrodetails" Component={Astrodetails}/>
  </Routes>
  <Footer/>
  <ScrollToTop/>
  </BrowserRouter>
  
    </>
  );
}


export default App;
